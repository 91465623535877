/***************************************************
  Imports
***************************************************/

import React from "react"

import Layout from "../components/Layout"
import PageNumber from "../components/PageNumber"
import IconButton, { ICONS } from "../components/IconButton"

import TeamGraphic from "../images/about-team-graphic.svg"
import GoldPartnerGraphic from "../images/about-goldpartner.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <Layout headerTheme="light" className="about-us">
      <section className="section-hero">
        <div className="col-3 span-5">
          <h1>The SimCrest Team</h1>
          <p>
            SimCrest is a Gold Microsoft Partner and an ERP (Enterprise Resource
            Planning) software provider dedicated to simplifying and
            streamlining highly specialized business processes for small and
            mid-sized businesses. With development experience in Microsoft
            Dynamics 365 Business Central dating back to 1990, SimCrest brings
            an unsurpassed depth of ERP application knowledge to our clients.
            <br />
            <br />
            Beginning in Europe, SimCrest is now recognized as one of the most
            experienced technology companies with offices in North Texas, South
            Florida, and Southern California. We are a value-added reseller of
            Dynamics 365 Business Central and Microsoft Dynamics CRM as well as
            an independent software vendor offering certified add-on solutions
            to Business Central. As a Microsoft Gold Certified Partner, we
            provide turn-key, value-adding customization and implementation to
            our customers, including the sale and integration of add-on products
            for specialized business needs.
            <br />
            <br />
            With over 25 years of industry-specific experience, SimCrest has
            helped organizations reduce operational costs and improve business
            efficiencies. From consulting, development, implementation, and
            training, through to on-going support, SimCrest is committed to
            offering high-quality services and integrated solutions through our
            comprehensive software knowledge, industry expertise, and proven
            project management skills.
          </p>
        </div>
      </section>

      <section className="section-hero-illustration">
        <img className="col-8 span-6" src={TeamGraphic} alt="SimCrest" />
      </section>

      <section className="section-mission">
        <div className="col-4 span-4">
          <h2>Our Mission</h2>
          <p>
            We help small and mid-sized organizations to be agile, productive,
            and efficient. We will continue to enhance business performance by
            providing valued service with simple, superior quality business
            management software. We pursue the goal of excellence and expect
            ourselves to be the code for success.
          </p>
        </div>
        <img
          className="col-9 span-4"
          src={GoldPartnerGraphic}
          alt="Gold Certified Microsoft Partner"
        />
      </section>

      <section className="section-values">
        <PageNumber
          className="col-3 span-11 dark"
          number="01"
          text="CORE VALUES"
        />
        <div className="col-4 span-9">
          <IconButton
            className="cursor-none"
            icon={ICONS.honesty}
            text="Honesty & Integrity"
          />
          <IconButton
            className="cursor-none"
            icon={ICONS.simplicity}
            text="Simplicity of Design and Use"
          />
          <IconButton
            className="cursor-none"
            icon={ICONS.importantAssets}
            text="Our Customers Are Our Most Important Assets"
          />
          <IconButton
            className="cursor-none"
            icon={ICONS.excellence}
            text="Striving for Excellence in Everything We Do"
          />
        </div>
      </section>
    </Layout>
  )
}
